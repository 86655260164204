import React, { useEffect, useRef } from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import {
  isScrollingDisabled,
  manageDisableScrolling,
} from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import Lottie from 'react-lottie-player';
import lottieJson from '../../components/siuhuu-lottie.json';

import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionCategory,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  UserNav,
  SectionRecentlyAdded,
  SectionBusiness,
  SectionBeauty,
  SectionTraditional,
  SectionPopular,
  SectionClothing,
  CustomUserNav,
  CurrencyExchangeCode,
  IconSpinner,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/siuhuuFacebook-1200x630.jpg';
import twitterImage from '../../assets/siuhuuTwitter-600x314.jpg';
import css from './LandingPage.module.css';
import { useState } from 'react';
import { createInstance } from '../../util/sdkLoader';
import {
  addMarketplaceEntities,
  getListingsById,
} from '../../ducks/marketplaceData.duck';
import { useStore } from 'react-redux';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { types as sdkTypes } from '../../util/sdkLoader';
import SectionHomeDecor from '../../components/SectionHomeDecor/SectionHomeDecor';
import HeroContainer from '../../components/heroContainer/heroContainer';
import landingPageImage1 from '../../assets/landingPageImage1.jpeg';
import landingPageImage2 from '../../assets/landingPageImage2.jpeg';
// import landingPageImage3 from '../../assets/landingPageImage3.jpeg';
import landingPageImage3 from '../../assets/cloth_image2.png';
import landingPageImage4 from '../../assets/landingPageImage4.jpeg';
import landingPageImage5 from '../../assets/landingPageImage5.jpeg';
import landingPageImage6 from '../../assets/landingPageImage6.jpeg';
import landingPageImage7 from '../../assets/Art_Siuhuu.svg';
import landingPageImage8 from '../../assets/landingPageImage8.jpeg';
import landingPageImage9 from '../../assets/landingPageImage9.jpeg';
import landingPageImage10 from '../../assets/landingPageImage10.jpeg';
// import landingPageImage11 from '../../assets/landingPageImage11.jpeg';
import landingPageImage11 from '../../assets/accomodation.png';
// import landingPageImage12 from '../../assets/landingPageImage12.jpeg';
import landingPageImage12 from '../../assets/bedding.png';
import jewellery from '../../assets/Jewellerynew.png';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const { UUID } = sdkTypes;

const fetchListingParams = {
  include: ['author', 'images', 'author.profileImage'],
  // 'fields.listing': [
  //   'title',
  //   'geolocation',
  //   'price',
  //   'publicData',
  //   'createdAt',
  // ],
  'fields.user': [
    'profile.displayName',
    'profile.abbreviatedName',
    'profile.publicData',
  ],
  'fields.image': [
    'variants.landscape-crop',
    'variants.landscape-crop2x',
    'variants.default',
  ],
  'limit.images': 1,
};

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    cState,
    onAddMarketplaceEntities,
    entities,
    onManageDisableScrolling,
  } = props;
  const primaryFilters = config.custom.filters.filter(
    f => f.group === 'primary'
  );

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'LandingPage.schemaTitle' },
    { siteTitle }
  );
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const [recentlyAdded, setRecentlyAdded] = useState([]);
  const [recentlyAddedIds, setRecentlyAddedIds] = useState([]);

  const [businessServices, setBusinessServices] = useState([]);
  const [businessServicesIds, setBusinessServicesIds] = useState([]);

  const [beautyListings, setBeautyListings] = useState([]);
  const [beautyListingsIds, setBeautyListingsIds] = useState([]);

  const [traditionalListings, setTraditionalListings] = useState([]);
  const [traditionalListingsIds, setTraditionalListingsIds] = useState([]);

  const [clothingListings, setClothingListings] = useState([]);
  const [clothingListingsIds, setClothingListingsIds] = useState([]);

  const [homeDecorListings, setHomeDecorListings] = useState([]);
  const [homeDecorListingsIds, setHomeDecorListingsIds] = useState([]);

  const [mostPopularListings, setMostPopularListings] = useState([]);
  const [mostPopularListingsIds, setMostPopularListingsIds] = useState([]);

  const [dataFetchInProgress, setDataFetchInProgress] = useState(true);
  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));
  useEffect(() => {
    const MAX_LISTING_NUMBER = 20;
    //for recently added listings
    const fetchNewlyAdded = async () => {
      return new Promise((resolve, reject) => {
        const paramsForNewlyAdded = {
          // page: 1,
          pub_isCustomQuote: false,

          perPage: MAX_LISTING_NUMBER,
          ...fetchListingParams,
        };

        sdkInstance.current.listings
          .query(paramsForNewlyAdded)
          .then(response => {
            // console.log(98754444, response);
            onAddMarketplaceEntities(response);
            const listingIds = response.data.data.map(m => m.id);
            // const listings = getListingsById(cState, listingIds);
            // console.log(1, listings);
            // setRecentlyAdded(listings);
            setRecentlyAddedIds(listingIds);
            return resolve(response);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      });
    };

    //for business services listings
    const fetchBusinessServices = async () => {
      return new Promise((resolve, reject) => {
        const paramsForBusinessServices = {
          // page: 1,
          pub_productCategory: 'businessServices',
          pub_isCustomQuote: false,
          perPage: MAX_LISTING_NUMBER,
          ...fetchListingParams,
        };
        sdkInstance.current.listings
          .query(paramsForBusinessServices)
          .then(response => {
            // console.log(response, 545);
            onAddMarketplaceEntities(response);
            const listingIds = response.data.data.map(m => m.id);
            // const listings = getListingsById(cState, listingIds);
            // console.log(2, listings);
            // setBusinessServices(listings);
            setBusinessServicesIds(listingIds);
            return resolve(response);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      });
    };

    //for beauty listings
    const fetchBeautyListings = async () => {
      return new Promise((resolve, reject) => {
        const paramsForBeautyListings = {
          // page: 1,
          pub_productCategory: 'beauty',
          pub_isCustomQuote: false,
          perPage: MAX_LISTING_NUMBER,
          ...fetchListingParams,
        };

        sdkInstance.current.listings
          .query(paramsForBeautyListings)
          .then(response => {
            onAddMarketplaceEntities(response);
            const listingIds = response.data.data.map(m => m.id);
            // const listings = getListingsById(cState, listingIds);
            // console.log(3, listings);
            // setBeautyListings(listings);
            setBeautyListingsIds(listingIds);
            return resolve(response);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      });
    };
    //for clothing listings
    const fetchColthingListings = async () => {
      return new Promise((resolve, reject) => {
        const paramsForColthingListings = {
          // page: 1,
          pub_productCategory: 'clothing',
          pub_isCustomQuote: false,
          perPage: MAX_LISTING_NUMBER,
          ...fetchListingParams,
        };
        sdkInstance.current.listings
          .query(paramsForColthingListings)
          .then(response => {
            // console.log(response, 545);
            onAddMarketplaceEntities(response);
            const listingIds = response.data.data.map(m => m.id);
            // const listings = getListingsById(cState, listingIds);
            // console.log(2, listings);
            // setBusinessServices(listings);
            setClothingListingsIds(listingIds);
            return resolve(response);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      });
    };

    //for home Decor listings
    const fetchHomeDecorListings = async () => {
      return new Promise((resolve, reject) => {
        const paramsForHOmeDecorListings = {
          // page: 1,
          pub_productCategory: 'homeDecor',
          pub_isCustomQuote: false,
          perPage: MAX_LISTING_NUMBER,
          ...fetchListingParams,
        };
        sdkInstance.current.listings
          .query(paramsForHOmeDecorListings)
          .then(response => {
            // console.log(response, 545);
            onAddMarketplaceEntities(response);
            const listingIds = response.data.data.map(m => m.id);
            // const listings = getListingsById(cState, listingIds);
            // console.log(2, listings);
            // setBusinessServices(listings);
            setHomeDecorListingsIds(listingIds);
            return resolve(response);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      });
    };

    //for traditional listings
    const fetchTraditionalListings = async () => {
      return new Promise((resolve, reject) => {
        const paramsForTraditionalListings = {
          // page: 1,
          pub_productCategory: 'traditional',
          pub_isCustomQuote: false,
          perPage: MAX_LISTING_NUMBER,
          ...fetchListingParams,
        };

        sdkInstance.current.listings
          .query(paramsForTraditionalListings)
          .then(response => {
            onAddMarketplaceEntities(response);
            const listingIds = response.data.data.map(m => m.id);
            // const listings = getListingsById(cState, listingIds);
            // console.log(4, listings);
            // setTraditionalListings(listings);
            setTraditionalListingsIds(listingIds);
            return resolve(response);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      });
    };

    //for mostPopular listings
    const fetchMostPopularListings = async () => {
      return new Promise((resolve, reject) => {
        let listingIds = [];
        const url = `${apiBaseUrl()}/api/listings/mostpopular`;
        axios
          .get(url)
          .then(resp => {
            const listingsInfo = resp.data;
            listingIds = listingsInfo.map(
              listing => new UUID(listing.listingId)
            );
            const paramsForMostPopularListings = {
              ids: listingIds,
              ...fetchListingParams,
            };
            return sdkInstance.current.listings.query(
              paramsForMostPopularListings
            );
          })
          .then(response => {
            onAddMarketplaceEntities(response);
            // const listings = getListingsById(cState, listingIds);
            // console.log(4, listings);
            // setTraditionalListings(listings);
            setMostPopularListingsIds(listingIds);
            return resolve(response);
          })
          .catch(e => {
            console.log(e);
            return reject(e);
          });
      });
    };

    Promise.all([
      fetchBusinessServices(),
      fetchNewlyAdded(),
      fetchBeautyListings(),
      fetchTraditionalListings(),
      fetchColthingListings(),
      fetchHomeDecorListings(),
      fetchMostPopularListings(),
    ])
      .then(resp => {
        setDataFetchInProgress(false);
      })
      .catch(err => {
        setDataFetchInProgress(false);
      });
  }, []);

  useEffect(() => {
    setBusinessServices(() => getListingsById(cState, businessServicesIds));
    setBeautyListings(() => getListingsById(cState, beautyListingsIds));
    setTraditionalListings(() =>
      getListingsById(cState, traditionalListingsIds)
    );
    setRecentlyAdded(() => getListingsById(cState, recentlyAddedIds));
    setClothingListings(() => getListingsById(cState, clothingListingsIds));
    setHomeDecorListings(() => getListingsById(cState, homeDecorListingsIds));
    setMostPopularListings(() =>
      getListingsById(cState, mostPopularListingsIds)
    );
  }, [
    entities,
    entities?.listings,
    recentlyAddedIds,
    traditionalListingsIds,
    businessServicesIds,
    beautyListingsIds,
    clothingListingsIds,
    mostPopularListingsIds,
  ]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer showSearchInMobile={true} />
          {/* <UserNav selectedPageName="landingPage" /> */}
          <CustomUserNav history={history} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMainClass}>
          {/* <div className={css.heroContainer}>
            <SectionHero
              className={css.hero}
              history={history}
              location={location}
            />
          </div> */}

          {dataFetchInProgress ? (
            <div className={css.loadingBody}>
              <Lottie
                loop
                className={css.loadingIcon}
                animationData={lottieJson}
                play
                style={{ width: 80, height: 80 }}
              />
            </div>
          ) : (
            <>
              <HeroContainer className={css.hero} />
              <ul className={css.sections}>
                <li className={css.section}>
                  <div className={css.circleContainer}>
                    {/* <h2 className={css.heading}>
                      Celebrate with the freshest finds from independent sellers
                    </h2> */}
                    <h2 className={css.heading}>
                      Celebrate the creations and talents of the Pacific
                    </h2>
                    <div className={css.grid}>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'gifts',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage1} />
                        </div>
                        <p className={css.label}>Gifts</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'homeDecor',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage2} />
                        </div>
                        <p className={css.label}>Home Decor</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'clothing',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage3} />
                        </div>
                        <p className={css.label}>Clothing</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'household',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage4} />
                        </div>
                        <p className={css.label}>Households</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'books',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage5} />
                        </div>
                        <p className={css.label}>Books</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'pets',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage6} />
                        </div>
                        <p className={css.label}>Pet Accessories</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            // pub_productCategory: 'shoes',
                            pub_productCategory: 'art',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage7} />
                        </div>
                        {/* <p className={css.label}>Shoes</p> */}
                        <p className={css.label}>Arts</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'beauty',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage8} />
                        </div>
                        <p className={css.label}>Beauty Products</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'fabrics',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage10} />
                        </div>
                        <p className={css.label}>Fabrics</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'businessServices',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage9} />
                        </div>
                        <p className={css.label}>Business Services</p>
                      </div>

                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'accommodation',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage11} />
                        </div>
                        {/* <p className={css.label}>Top Rated Trends</p> */}
                        <p className={css.label}>Accommodation</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'bedding',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage12} />
                        </div>
                        {/* <p className={css.label}>On Sale</p> */}
                        <p className={css.label}>Bedding</p>
                      </div>
                    </div>

                    <div className={css.mobileGrid}>
                      <div
                        className={css.item}
                        onClick={() => {
                          // const params = {
                          //   pub_productCategory: 'gifts',
                          // };
                          // history.push(
                          //   createResourceLocatorString(
                          //     'SearchPage',
                          //     routeConfiguration(),
                          //     {},
                          //     params
                          //   )
                          // );
                        }}
                      >
                        <div
                          className={css.image}
                          onClick={() => {
                            const params = {
                              pub_productCategory: 'jewellery',
                            };
                            history.push(
                              createResourceLocatorString(
                                'SearchPage',
                                routeConfiguration(),
                                {},
                                params
                              )
                            );
                          }}
                        >
                          <img src={jewellery} />
                        </div>
                        <p className={css.label}>jewellry</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'homeDecor',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage2} />
                        </div>
                        <p className={css.label}>Home Decor</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'clothing',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage3} />
                        </div>
                        <p className={css.label}>Clothing</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'household',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage4} />
                        </div>
                        <p className={css.label}>Households</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'books',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage5} />
                        </div>
                        <p className={css.label}>Books</p>
                      </div>
                      <div
                        className={css.item}
                        onClick={() => {
                          const params = {
                            pub_productCategory: 'pets',
                          };
                          history.push(
                            createResourceLocatorString(
                              'SearchPage',
                              routeConfiguration(),
                              {},
                              params
                            )
                          );
                        }}
                      >
                        <div className={css.image}>
                          <img src={landingPageImage6} />
                        </div>
                        <p className={css.label}>Pet Accessories</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li className={css.section}>
                  {/* <div className={css.sectionCategory}> */}
                  <div className={css.sectionContentFirstChild}>
                    <SectionCategory />
                  </div>
                </li>
                {recentlyAdded.length > 0 ? (
                  <li className={css.section}>
                    <div className={css.sectionContent}>
                      {/* <SectionLocations /> */}
                      <SectionRecentlyAdded
                        recentlyAdded={recentlyAdded}
                        intl={intl}
                        history={history}
                      />
                    </div>
                  </li>
                ) : null}
                {traditionalListings.length > 0 ? (
                  <li className={css.sectionService}>
                    <div className={css.sectionContent}>
                      <SectionTraditional
                        history={history}
                        traditionalListings={traditionalListings}
                        intl={intl}
                      />
                    </div>
                  </li>
                ) : null}
                {beautyListings.length > 0 ? (
                  <li className={css.section}>
                    <div className={css.sectionContent}>
                      <SectionBeauty
                        history={history}
                        beautyListings={beautyListings}
                        intl={intl}
                      />
                    </div>
                  </li>
                ) : null}
                {homeDecorListings.length > 0 ? (
                  <li className={css.section}>
                    <div className={css.sectionContent}>
                      <SectionHomeDecor
                        homeDecorListings={homeDecorListings}
                        history={history}
                      />
                    </div>
                  </li>
                ) : null}
                {clothingListings.length > 0 ? (
                  <li className={css.sectionService}>
                    <div className={css.sectionContent}>
                      <SectionClothing
                        clothingListings={clothingListings}
                        history={history}
                      />
                    </div>
                  </li>
                ) : null}
                {businessServices.length > 0 ? (
                  <li className={css.sectionService}>
                    <div className={css.sectionContent}>
                      <SectionBusiness
                        history={history}
                        businessServices={businessServices}
                        intl={intl}
                      />
                    </div>
                  </li>
                ) : null}

                {mostPopularListings.length > 0 ? (
                  <li className={css.section}>
                    <div className={css.sectionContent}>
                      <SectionPopular
                        recentlyAdded={mostPopularListings}
                        history={history}
                        onManageDisableScrolling={onManageDisableScrolling}
                      />
                    </div>
                  </li>
                ) : null}

                {/*clothingListings.length > 0 ? (
                <li className={css.sectionService}>
                <div className={css.sectionContent}>
                <SectionClothing
                clothingListings={clothingListings}
                history={history}
                />
                </div>
                </li>
              ) : null*/}

                <li className={css.sectionBottom}>
                  <div className={css.sectionContent}>
                    <SectionHowItWorks
                      currentUserListing={currentUserListing}
                      currentUserListingFetched={currentUserListingFetched}
                      history={history}
                    />
                  </div>
                </li>
              </ul>
            </>
          )}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  //  onSearchSubmit: func.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  // const listins = getListingsById(lids)
  const { entities } = state.marketplaceData;
  // console.log(entities);
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    // cState: { marketplaceData: state.marketplaceData },
    cState: state,
    entities,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddMarketplaceEntities: response =>
      dispatch(addMarketplaceEntities(response)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
